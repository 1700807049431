import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  CssBaseline,
  Avatar,
  Link,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  CircularProgress,
} from "@mui/material";
import LockOpen from "@mui/icons-material/LockOpen";
import { useAppState } from "../../reduxModule";
import axios from "axios";

const LoginPageView = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const {
    handleResetStore,
    handleUpdateLoggedInUser,
    handleUpdateCompanyImageUrl,
  } = useAppState();
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [multiCompanyError, setMultiCompanyError] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      const body = {
        login_id: loginId,
        password: password,
        company_id: selectedCompany,
      };
      const response = await fetch(apiUrl + "/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        credentials: "include",
      });

      const data = response.status !== 403 ? await response.json() : null;

      switch (response.status) {
        case 200:
          handleUpdateLoggedInUser(data);
          handleUpdateCompanyImageUrl(data.company_image_url);
          navigate("/dashboard");
          break;
        case 251:
          setCompanies([]);
          setError("Login failed. No company data found.");
          break;
        case 252:
          setSelectedCompany("");
          setMultiCompanyError(
            "Multi company login! Select a company below then click sign in."
          );
          setCompanies(data.companies);
          break;
        case 253:
          setError("Login blocked. You do not have access to this site.");
          setSelectedCompany("");
          setCompanies([]);
          break;
        case 254:
          setSelectedCompany("");
          setError("Login failed. Please check your credentials.");
          setCompanies([]);
          break;
        default:
          setSelectedCompany("");
          setError("Login failed. Please check your credentials.");
          setCompanies([]);
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/"
    ) {
      handleLogout();
    }
  }, []);

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        apiUrl + "/api/auth/logout",
        {},
        { withCredentials: true }
      );

      switch (response.status) {
        case 200:
          localStorage.clear();
          handleResetStore();
          break;
        default:
          setError("Logout failed");
      }
    } catch (error) {
      setError("An error occurred during logout");
    }
  };

  const handleCompanySelect = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleResetLogin = () => {
    setCompanies([]);
    setSelectedCompany("");
    setLoginId("");
    setPassword("");
    setError("");
    setMultiCompanyError("");
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h3">
          S a l e s M a c h i n e X
        </Typography>
        <Avatar sx={{ m: 2, bgcolor: "primary.main" }}>
          <LockOpen />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ mt: 3 }}>
          Sign In
        </Typography>

        <Box
          component="form"
          onSubmit={handleLogin}
          noValidate
          sx={{ mt: 1, width: "100%" }}
        >
          {companies.length === 0 && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="loginId"
                label="Login ID"
                name="loginId"
                autoComplete="email"
                autoFocus
                value={loginId}
                onChange={(e) => setLoginId(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </>
          )}
            <>
              {multiCompanyError && (
                <Box sx={{ mt: 2 }}>
                  <Alert severity="success">{multiCompanyError}</Alert>
                </Box>
              )}
              {companies.length > 0 && (
                <Box sx={{ pt: 1, px: 0 }}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="company-select-label">
                      Select Company
                    </InputLabel>
                    <Select
                      labelId="company-select-label"
                      id="company-select"
                      value={selectedCompany}
                      label="Select Company"
                      onChange={handleCompanySelect}
                    >
                      {companies.map((company) => (
                        <MenuItem
                          key={company.company_id}
                          value={company.company_id}
                        >
                          {company.company_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </>

          {isLoading ? (
            <Button fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="body2">Checking Credentials</Typography>
                <CircularProgress size={24} color="inherit" />
              </Box>
            </Button>
          ) : (
            <>
              {" "}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
              >
                {companies.length > 0 ? "Sign in to company" : "Sign In"}
              </Button>
              {companies.length > 0 && (
                <Link
                  component="button"
                  variant="body2"
                  onClick={handleResetLogin}
                  sx={{
                    mt: 0,
                    mb: 2,
                    display: "block",
                    width: "100%",
                    color: "black",
                  }}
                >
                  Restart Login
                </Link>
              )}
            </>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Link href="/forgot-password" variant="body2">
              Forgot password?
            </Link>
            <Link
              href="https://www.salesmachinex.com"
              variant="body2"
              target="_blank"
            >
              {"Don't have an account? Contact us"}
            </Link>
          </Box>
          {error && (
            <Alert severity="error" sx={{ mt: 5 }}>
              {error}
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPageView;
