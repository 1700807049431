import React, { lazy, Suspense, useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import { useAppState } from "./reduxModule";

import Dashboard from "./components/Dashboard/Dashboard";

import LoginView from "./components/Authentication/LoginPageView";
import ResetPasswordView from "./components/Authentication/ResetPasswordView";
import ForgotPasswordView from "./components/Authentication/ForgotPasswordView";

export const AppRoutes = () => {
  // Lazy load components
  const CustomerListView = lazy(() =>
    import("./components/Views/Customer/CustomerList")
  );
  const CustomerDetailView = lazy(() =>
    import("./components/Views/Customer/CustomerDetail")
  );

  const UserListView = lazy(() => import("./components/Views/User/UserList"));
  const CustomerNewView = lazy(() =>
    import("./components/Views/Customer/CustomerNew")
  );
  const UserNewPortalX = lazy(() =>
    import("./components/Views/User/UserNewPortalX")
  );
  const UserDetailView = lazy(() =>
    import("./components/Views/User/UserDetail")
  );
  const ItemListView = lazy(() => import("./components/Views/Item/ItemList"));
  const ItemDetailView = lazy(() =>
    import("./components/Views/Item/ItemDetail")
  );
  const ItemNewView = lazy(() => import("./components/Views/Item/ItemNew"));
  const ContactDetailView = lazy(() =>
    import("./components/Views/Contact/ContactDetail")
  );
  const OrderListView = lazy(() =>
    import("./components/Views/SalesDocument/Order/OrderListView")
  );
  const OrderDetailView = lazy(() =>
    import("./components/Views/SalesDocument/Order/OrderDetailView")
  );
  const ImageLibraryList = lazy(() =>
    import("./components/Views/Settings/ImageLibrary/ImageLibraryList")
  );
  const SettingsView = lazy(() =>
    import("./components/Views/Settings/SettingsView")
  );
  const ContactListView = lazy(() =>
    import("./components/Views/Contact/ContactList")
  );
  const LocationListView = lazy(() =>
    import("./components/Views/Settings/Location/LocationListView")
  );
  const LocationDetailView = lazy(() =>
    import("./components/Views/Settings/Location/LocationDetailView")
  );
  const LocationNewView = lazy(() =>
    import("./components/Views/Settings/Location/LocationNew")
  );
  const PaymentTransactionListView = lazy(() =>
    import(
      "./components/Views/Settings/PaymentTransactions/PaymentTransactionListView"
    )
  );
  const SalesTeamListView = lazy(() =>
    import("./components/Views/Settings/SalesTeam/SalesTeamListView")
  );
  const CountyListView = lazy(() =>
    import("./components/Views/Settings/County/CountyListView")
  );
  const CountryListView = lazy(() =>
    import("./components/Views/Settings/Country/CountryListView")
  );
  const CountryRegionListView = lazy(() =>
    import("./components/Views/Settings/CountryRegion/CountryRegionListView")
  );
  const CountryNewView = lazy(() =>
    import("./components/Views/Settings/Country/CountryNew")
  );
  const CountryDetailView = lazy(() =>
    import("./components/Views/Settings/Country/CountryDetailView")
  );
  const CountyDetailView = lazy(() =>
    import("./components/Views/Settings/County/CountyDetailView")
  );
  const CountryRegionDetailView = lazy(() =>
    import("./components/Views/Settings/CountryRegion/CountryRegionDetailView")
  );
  const CountryRegionNewView = lazy(() =>
    import("./components/Views/Settings/CountryRegion/CountryRegionNew")
  );
  const CountyListNewView = lazy(() =>
    import("./components/Views/Settings/County/CountyNew")
  );
  const CollectionListView = lazy(() =>
    import("./components/Views/Collection/CollectionList")
  );

  const CollectionItemListListView = lazy(() =>
    import("./components/Views/Collection/CollectionItemList")
  );

  const WarehouseLocationListView = lazy(() =>
    import("./components/Views/Settings/WarehouseLocation/WarehouseLocationListView")
  );

  const WarehouseLocationDetailView = lazy(() =>
    import(
      "./components/Views/Settings/WarehouseLocation/WarehouseLocationDetailView"
    )
  );

  const WarehouseLocationNewView = lazy(() =>
    import("./components/Views/Settings/WarehouseLocation/WarehouseLocationNewView")
  );

   const PaymentTermListView = lazy(() =>
     import(
       "./components/Views/Settings/PaymentTerm/PaymentTermListView"
     )
   );

   const PaymentTermDetailView = lazy(() =>
     import(
       "./components/Views/Settings/PaymentTerm/PaymentTermDetailView"
     )
   );

   const PaymentTermNewView = lazy(() =>
     import(
       "./components/Views/Settings/PaymentTerm/PaymentTermNewView"
     )
   );

  const navigate = useNavigate();
  const { sideMenuOpen, handleUpdateSideMenuOpen } = useAppState();

  const setupAxiosInterceptors = (navigate) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          const currentPath = window.location.pathname;
          if (!currentPath.startsWith("/reset-password")) {
            navigate("/login");
          }
        }
        return Promise.reject(error);
      }
    );
  };

  useEffect(() => {
    setupAxiosInterceptors(navigate);

    //Close the side menu
    handleUpdateSideMenuOpen(false);
  }, [navigate]);

  // Loading fallback component
  const LoadingFallback = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />

        <Route
          path="/customers"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CustomerListView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/customers/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CustomerDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/customers/new"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CustomerNewView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/items"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ItemListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/items/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ItemDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/items/new"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ItemNewView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/users/new/portalx"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <UserNewPortalX />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/orders"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <OrderListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/orders/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <OrderDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/users/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <UserDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/contacts"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ContactListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/contacts/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ContactDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/displaycases/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <Typography>TODO: Display Cases Route</Typography>
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/collections"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CollectionListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/collections/:collectionId"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CollectionListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/collections/items/:collectionId"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CollectionItemListListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/locations"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <LocationListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/locations/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <LocationDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/locations/new"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <LocationNewView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/image-library"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ImageLibraryList />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/payment-transactions"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <PaymentTransactionListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/sales-teams"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <SalesTeamListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/county-setup"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CountyListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/county-setup/new"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CountyListNewView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/county-setup/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CountyDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/country-setup"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CountryListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/country-setup/new"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CountryNewView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/country-setup/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CountryDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/country-region-setup"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CountryRegionListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/country-region-setup/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CountryRegionDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/country-region-setup/new"
          element={
            <Dashboard>
              <CountryRegionNewView />
            </Dashboard>
          }
        />
        <Route
          path="/settings/warehouse-locations"
          element={
            <Dashboard>
              <WarehouseLocationListView />
            </Dashboard>
          }
        />
        <Route
          path="/settings/warehouse-locations/:idType/:objectId/"
          element={
            <Dashboard>
              <WarehouseLocationDetailView />
            </Dashboard>
          }
        />
        <Route
          path="/settings/warehouse-locations/new"
          element={
            <Dashboard>
              <WarehouseLocationNewView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/payment-terms"
          element={
            <Dashboard>
              <PaymentTermListView />
            </Dashboard>
          }
        />
        <Route
          path="/settings/payment-terms/:idType/:objectId/"
          element={
            <Dashboard>
              <PaymentTermDetailView />
            </Dashboard>
          }
        />
        <Route
            path="/settings/payment-terms/new"
          element={
            <Dashboard>
              <PaymentTermNewView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/*"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <SettingsView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/users"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <UserListView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route path="/login" element={<LoginView />} />
        <Route path="/reset-password/:token" element={<ResetPasswordView />} />
        <Route path="/forgot-password/" element={<ForgotPasswordView />} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>
  );
};
